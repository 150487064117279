@import "colors";
.home{
    width: 100%;
    height: 100vh;
    background:$second;
    border-radius: 0 0 200px 200px;
    >main{
        
        position: absolute;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding: 2rem;
        justify-content: center;

        h1{
            font: 400 6rem $text;
            color: $first;
        }
        p{
            font: 900 1rem $text;
            color: $first;
        }


    }
    &::before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: no-repeat center/auto url(../assets/1.png);
        filter: blur(1px);
        border-radius: 0 0 200px 200px;
    }
 }

 .home2{
    width: 100%;
    height: 100vh;
    background: $second;
    padding: 500px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -200px;
     
    > img{
        width: 30%;
        animation: imgup 0.7s linear infinite alternate;
    }
    > div{
        
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5rem;
        height: 100vh;
         

        p{
            color: $first;
            letter-spacing: 2px;
            word-spacing: 5px;
            font:100 1.2rem $text2;
        }
    }
 }

 .home3{
    width: 100%;
    height: 120vh;
    background: $second;
    margin-top: -200px;
    display:flex ;
    > div{
        width: 70%;
        height: 100vh;
        background: $first;
        border-radius: 0 200px 200px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        > h1{

            text-transform: uppercase;
            border-bottom: 2px solid $dark;
            padding: 0.5rem;
            width: 15rem;
            color: $dark;
        }
         > p{
            padding: 5rem;
            letter-spacing: 1px;
            word-spacing: 5px;
            line-height: 170%;
            font: italic 100 1.2rem $text2;
         }
    }
 }
 .home4{
    width: 100%;
    height: 100vh;
    background: $second;
    
    display:flex ;
    justify-content: flex-end;
    > div{
        background-color:$third;
        width: 70%;
        height: 70vh;
        border-radius: 200px 0 0 200px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > h1{
            color: $first;
            text-transform: uppercase;
            width: 140px;
            border-bottom: 2px solid $first;
            padding: 0.5rem;
        }
        > article{
            display:flex ;
            margin: 2rem 0;
            flex-wrap: wrap;
            justify-content: center;
            >div{
                animation: imgup 0.7s linear infinite alternate;
                background-color:$first;
                margin: 1rem;
                height: 10rem;
                width: 10rem;
                border-radius: 50%;
                display:flex ;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: $dark;
                cursor: pointer;
                transition: all 0.5s;
                &:hover{
                    filter: invert(1);
                }
                svg{
                    font-size: 4rem;
                }
                p{
                    font: 900 1.2rem $text2;
                }
                
            }
        }
    }
 }

 @keyframes imgup{
    to{
        transform: translateY(-10px);
    }
 }